import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import { Router } from "@reach/router";
import { navigate } from "gatsby";
import { useState } from "react";
import HeaderComponent from "../../components/HeaderComponent";
import PersonalAccount from "../../features/account/personal-account";
import { useTabStyles } from "../../utils/styles-util";

const useStyles = makeStyles({
  black: {
    color: "#000000",
  },
  unselect: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AccountSetting({ location }) {
  const classes = useStyles();
  const tabsStyles = useTabStyles();
  const { pathname } = location;
  const [value, setValue] = useState(() => {
    return pathname.indexOf("joint-accounts") !== -1 ? 1 : 0;
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
    newValue === 1 && navigate("/account/joint-accounts");
    newValue === 0 && navigate("/account/");
  };

  return (
    <div className="sharing-referral-page account-page">
      <HeaderComponent
        headerContent={
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="secondary"
            textColor="inherit"
            classes={{
              root: tabsStyles.tabs,
              flexContainer: tabsStyles.tabsFlexContainer,
              indicator: tabsStyles.indicator,
            }}
          >
            <Tab
              label="Personal Account"
              {...a11yProps(0)}
              classes={{
                textColorInherit:
                  value === 0 ? classes.black : classes.unselect,
              }}
            />
          </Tabs>
        }
      />
      <div className="account-page__main-container">
        <Router basepath="/account">
          <PersonalAccount path="/*" />
        </Router>
      </div>
    </div>
  );
}
